import React from 'react';
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import {
  TextField as MuiTextField,
  Tooltip
} from "@material-ui/core";

const TextField = styled(MuiTextField)(spacing);

const GradeTextField = ({isLesson,grade,onChange, ...props }) => {
  let tooltipText = "Type the question numbers that the student missed, separated by commas.\ne.g. 4, 5, 9, 12, 13";

  if (!!isLesson) {
    tooltipText = "Type the sections and questions numbers that the student missed, separated by commas.\ne.g. Rep1 2,6 Rep2 3,4,5";
  }

  return (
    <Tooltip size="small" title={<span style={{ whiteSpace: 'pre-line' }}>{tooltipText}</span>} {...props}>
      <TextField value={grade} autoComplete="off" list="autocompleteOff" onChange={(e) => onChange(e.target.value)} id="grade_text_field" size="small" placeholder="Incorrect Questions..." variant="outlined" fullWidth my={2} />
    </Tooltip>
  );
}

export default GradeTextField;