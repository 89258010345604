import axiosInstance from './axios-instance';


export const getLocations = () => {
  return axiosInstance.get("/metadata/locations");
};

export const getRegions = () => {
  return axiosInstance.get("/metadata/regions");
};

export const getCountries = () => {
  return axiosInstance.get("/metadata/countries");
};