import React, { useContext } from 'react';
import AppContext from '../../AppContext';

const findByType = (children, component) => {
    const result = [];
    const type = [component.displayName] || [component.name];

    React.Children.forEach(children, child => {
        const childType = child && child.type && (child.type.displayName || child.type.name);
        if (type.includes(childType)) {
            result.push(child);
        }
    });
    return result[0];
};

const check = ({location, user, claim}) => {

    if (user.isGod) {
        return true;
    }

    if (!location || !user || !claim)
    {
        return false;
    }

    const claims = Array.isArray(claim) ? claim : [claim];

    const locationPerms = user.permissions[location.id];
    return locationPerms?.some(perm => claims.some(c => perm === c)) ?? false;
};

const Allow = () => null;
Allow.displayName = "Allow";
const Deny = () => null;
Deny.displayName = "Deny";

const Permission = (props) => {

    const { me, currentLocation } = useContext(AppContext);

    const hasPermission = check({...props, user: me, location: props?.location ?? currentLocation});
    const { children, claim } = props;
    let component = null;

    if (!me || !claim)
    {
        return <></>;
    }

    if (hasPermission) {
        component = findByType(children, Allow);
    } else {
        component = findByType(children, Deny);
    }

    return component?.props?.children ?? <></>;
};

Permission.defaultProps = {
    location: null,
    claim: ""
};

Permission.Allow = Allow;
Permission.Deny = Deny;

export default Permission;