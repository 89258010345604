import React, { useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Popover, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { PinDrop, Close } from "@material-ui/icons";
import AppContext from '../../AppContext';
import { grey, blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 300,
        padding: theme.spacing(1),
        '& li': {
            cursor: 'pointer'
        },
        '& li:hover': {
            borderRadius: '5px',
            backgroundColor: theme.palette.grey[200]
        }
    },
    listItem: {
        'li': {
            cursor: 'pointer'
        },
        'li:hover': {
            borderRadius: '5px',
            backgroundColor: theme.palette.grey[200]
        }
    }
}));

const LocationSpot = ({loc, open, ...rest}) => {
    return (
    <ListItem
        selected={open}
        {...rest}>
        <ListItemIcon>
            <PinDrop htmlColor={rest.selected || open ? blue[400] : grey[400]} />
        </ListItemIcon>
        <ListItemText primary={loc?.name ?? "No Location Set"} secondary={!!loc ? `${loc.locality}, ${loc.regionName}` : "N/A"} />
    </ListItem>);
}

const LocationMultiselect = () => {

    const classes = useStyles();
    const { defaultLocations, currentLocation, setCurrentLocation } = useContext(AppContext);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const updateLocation = (loc) => {
        setCurrentLocation(loc);
        handleClose();
    };

    const clearLocation = () => {
        setCurrentLocation(null);
        handleClose();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div className={classes.root}>
            <LocationSpot loc={currentLocation} onClick={handleClick} open={open} />
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List>
                    {defaultLocations.map((loc, ix) =>
                        <LocationSpot
                            loc={loc}
                            key={loc.id}
                            onClick={() => updateLocation(loc)}
                            selected={currentLocation?.id === loc.id}
                            divider={!!currentLocation && ix === defaultLocations.length - 1}
                            root={classes.listItem}
                        />)}
                    {!!currentLocation ?
                        <ListItem>
                            <ListItemText>
                                <Button
                                    color="primary"
                                    onClick={clearLocation}>
                                    Remove Current Location <Close />
                                </Button>
                            </ListItemText>
                        </ListItem>
                        : <></>}
                </List>
            </Popover>
        </div>
    );
  };

export default LocationMultiselect;