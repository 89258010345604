import axiosInstance from './axios-instance';

export const getBinder = (userId) => {
  return axiosInstance.get(`/binders/${userId}`);
};

export const getStudentNotes = (userId) => {
  return axiosInstance.get(`/binders/notes/${userId}`);
};

export const updateStudentNotes = (sessionId, tutorId, note) => {
  return axiosInstance.post(`/binders/notes/${sessionId}`, { TutorId: tutorId, Note: note });
};

export const addGrade = (tutorId, studentId, programName, lesson, grade, studentCmsId) => {
  return axiosInstance.post(`/binders/grades/${tutorId}/${studentId}`,
    {
      Program: programName, 
      Lesson: lesson, 
      Grade: grade, 
      StudentCmsId: studentCmsId
    });
};

export const getGrades = (locationId) => {
  return axiosInstance.get(`/binders/grades/${locationId}`);
};

export const updateGrades = (ids) => {
  return axiosInstance.post('/binders/grades',ids);
};

export const addReadingGrade = (tutorId, studentId,studentCmsId, grades) => {
  return axiosInstance.post(`/binders/grades/${tutorId}/${studentId}/${studentCmsId}/reading`, grades);
};
