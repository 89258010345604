import React from "react";

import {
  TextField,
  MenuItem
} from "@material-ui/core";

const BinderGradeDropdown = ({ programType, isComposition, grade, onChange, ...props }) => {
  let options;
  switch (programType) {
    case "Algebra":
    case "Writing":
    case "Standard Math":
      options = [isComposition ? "Mastered (Successful Completion)" : "Mastered (2 Reps at 100%)", "Not-Mastered (Repeat)"];
      break;
    case "Beginning Reading":
      options = ["Mastered", "Not-Mastered"];
      break;
    default:
      options = [];
  }
  if(!options.includes(grade)){
    grade = "";
  }
  
  return (
    <TextField
      id="binderGradeDropdownId"
      label="Grade"
      variant="outlined"
      fullWidth
      my={2}
      value={grade}
      onChange={(e) => onChange(e.target.value)}
      select
      {...props}
    >
      {/* <MenuItem key="emptyItem" value={""}>Grade...</MenuItem> */}
      {options.map((c) => <MenuItem key={c} value={c}>{c}</MenuItem>)}
    </TextField>);
};

export default BinderGradeDropdown;