
import { createContext } from "react";

const ApplicationContext = {
  locations: [],
  regions: [],
  countries: [],
  me: {},
  updateMe: () => {},
  defaultLocations: [],
  setDefaultLocation: (locations) => {},
  currentLocation: [],
  setCurrentLocation: (locations) => {},
  loading: true
};

const AppContext = createContext(ApplicationContext);

export default AppContext;