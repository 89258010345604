import React from 'react';
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { withSnackbar } from 'notistack';
import { makeStyles } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import {
  Grid,
  Button as MuiButton,
  Typography,
  IconButton
} from "@material-ui/core";
import { Copy } from "react-feather";
import BinderGradeDropdown from "../drop-downs/BinderGradeDropdown";
import GradeTextField from "./GradeTextField";
import { addGrade } from "../../services/binder-service";

const useStyles = makeStyles((theme) => ({
  fillWidth: {
    width: "100%",
  },
  fill: {
    height: "100%",
    border: 0,
    margin: "0 auto",
    display: "block"
  }
}));

const Button = styled(MuiButton)(spacing);

const Lesson = ({ enqueueSnackbar, ...props }) => {
  const [gradeText, setGradeText] = React.useState("");
  const classes = useStyles();
  const location = useLocation();
  const pageName = location.state.name
  const progType = location.state.programType;
  const gradeLesson = location.state.gradeLesson;
  const routeParams = props.match.params;

  const isComposition = location.pathname.includes("composition");
  const useInput = pageName === "Pre-Test" || pageName === "Post-Test" || progType === "CC Math";
  const isLesson = pageName.includes("Lesson");

  React.useEffect(() => {
    setGradeText("");
  }, [props.match.url]);

  const copyToClipboard = (url) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url);
      enqueueSnackbar('Link copied to clipboard!', { variant: "info" });
    }
  };

  const onChange = (value) => {
    setGradeText(value);
  };

  const submitGrade = () => {
    let studentId = routeParams.lmsId;
    let tutorId = routeParams.tutorId;
    let studentCmsId = routeParams.cmsId;
    const programText = progType === 'CC Math' ? 'Math Concepts' : progType;

    addGrade(tutorId, studentId, programText, gradeLesson, gradeText, studentCmsId).then(_ => {
      enqueueSnackbar('Grade Submitted', { variant: "success" });
      setGradeText('');
    }).catch(err => {
      console.error(err);
      enqueueSnackbar('Grade failed to save', { variant: "error" });
    });
  };
  
  return (
    <>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item md={3} xs={12}>
          <Typography variant="h3">
            {pageName}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          {useInput ?
            (<GradeTextField onChange={onChange} grade={gradeText} isLesson={isLesson} />) :
            (<BinderGradeDropdown onChange={onChange} grade={gradeText} size="small" programType={progType} isComposition={isComposition} />)}
        </Grid>
        <Grid item md={2} xs={11}>
          <Button type="button" variant="contained" color="secondary" aria-label="Submit grade" onClick={submitGrade}>
            Submit
          </Button>
        </Grid>
        <Grid item md={1} xs={1}>
          <IconButton color="primary" aria-label="Copy Answer Key Link" component="span" onClick={() => copyToClipboard(location.state.lesson_link)}>
            <Copy />
          </IconButton>
        </Grid>
      </Grid>
      {
        !!location.state.answer_key_link ?
          (<div className={`${classes.fill} ${classes.fillWidth}`}>
            <img src={location.state.answer_key_link} className={`${classes.fillWidth}`} alt="Lesson Answer Key" />
          </div>) : <Typography variant="body" component="div" style={{paddingTop: '10px'}}>No Answer Key Available</Typography>
      }
    </>
  );
};

export default withSnackbar(Lesson);
