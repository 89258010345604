import axios from "axios";
import createAuth0Client from "@auth0/auth0-spa-js";
import initOptions from "../../src/auth_config.json";

const axiosInstance = axios.create({
  //baseURL: 'https://...'
});
axiosInstance.interceptors.request.use(async (config) => {
  const auth0Client = await createAuth0Client({...initOptions, client_id: initOptions.clientId});
  const token = await auth0Client.getTokenSilently();
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});
export default axiosInstance;