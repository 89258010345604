import { useState } from "react";

export const useLocation = () => {
    const CURRENT_SAVED_LOCATION = "tc.currentlocation";
    const [defaultLocations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const setDefaultLocation = (data) => {
        setLocations(data);
        if (selectedLocation === null) {
            const savedValue = localStorage.getItem(CURRENT_SAVED_LOCATION);
            if (!!savedValue) {
                const value = JSON.parse(savedValue)
                if(value != null && !data.some(x=> x.id === value.id)){
                    setCurrentLocation(null);
                }else{
                    setCurrentLocation(value);
                }
            }
        }
    };
    const setCurrentLocation = (data) => {
        setSelectedLocation(data);
        localStorage.setItem(CURRENT_SAVED_LOCATION, JSON.stringify(data));
    };
    return {
        defaultLocations,
        setDefaultLocation,
        currentLocation: selectedLocation,
        setCurrentLocation
    };
};