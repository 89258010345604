import React, { useState, useContext } from "react";
import styled, { withTheme } from "styled-components";

import {
  Avatar,
  Badge,
  Grid,
  Hidden,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  makeStyles
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";
import Alert from '@material-ui/lab/Alert';
import { useAuth0 } from '../react-auth0-spa';
import { useHistory } from 'react-router-dom';
import LocationMultiselect from "../components/drop-downs/LocationMultiselect";
import AppContext from "../AppContext";
import TCLogo from "../components/TCLogo";

const AppBar = styled(MuiAppBar)`
  background: ${props => props.isauthenticated !== 'true' ? props.theme.sidebar.background : props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const StyledBadge = styled(Badge)`
  margin-right: ${props => props.theme.spacing(1)}px;

  span {
    color: black;
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`

const MiniAvatar = styled(Avatar)`
  height: 30px;
  width: 30px;
  font-size: 14px;
`;

const useStyles = makeStyles((theme) => ({
  stacked: {
    display: "flex",
    flexGrow: 2,
    justifyContent: "flex-end",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column"
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: "row"
    }
  },
  fill: {
    flexGrow: 2,
    alignSelf:"center"
  }
}));

function UserMenu() {

  const [anchorMenu, setAnchorMenu] = useState(null);
  const history = useHistory();
  const { logout } = useAuth0();
  const { me, currentLocation } = useContext(AppContext);

  const toggleMenu = event => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const goToProfile = () => {
    closeMenu();
    history.push("/user/profile");
  };

  const signOut = () => {
    logout({returnTo:window.location.origin});
  };

  const goToHelp = () => {
    closeMenu();
    if (isExternalUser()) {
      window.open("https://www.tutoringclub.com/blog/category/help/", "_blank");
    } else {
      window.open("https://www.tutoringclub.com/blog/category/knowledge-base/", "_blank");
    }
  };

  const isExternalUser = () => {
    return currentLocation == null
      ||
        (currentLocation != null
        &&
          (
            me.userTypes[currentLocation.id].userType === "Student"
            ||
            me.userTypes[currentLocation.id].userType === "Parent"
          )
        );
  };

  return (
    <React.Fragment>
      <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <StyledBadge
              overlap="circle"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              variant="dot"
        >
          <MiniAvatar alt={me?.initials?.toUpperCase() ?? "?"} src={me?.avatarUrl}>{me?.initials}</MiniAvatar>
        </StyledBadge>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={goToProfile}>
          Profile
        </MenuItem>
        <MenuItem onClick={goToHelp}>
          Help
        </MenuItem>
        <MenuItem onClick={signOut}>
          Sign out
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

const Header = ({ onDrawerToggle, theme }) => {
  const { currentLocation, loading } = useContext(AppContext);
  const { isAuthenticated } = useAuth0();
  const classes = useStyles();
  return (
  <React.Fragment>
    <AppBar position="sticky" elevation={0} isauthenticated={isAuthenticated.toString()}>
      <Toolbar>
        <Grid container alignItems="center">
          { !isAuthenticated ? <TCLogo />
          : <>
              <Hidden lgUp>
                <Grid item>
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={onDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Hidden>
              <Grid item className={classes.stacked}>
                <Hidden smDown>
                  <Grid item className={classes.fill}>
                      {!loading && !currentLocation ?
                        <Alert severity="warning">You currently have no current location set.</Alert>
                        : <></>}
                  </Grid>
                </Hidden>
                <Grid item>
                    <LocationMultiselect />
                </Grid>
              </Grid>
              <Grid item>
                <UserMenu />
              </Grid>
            </> }
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>);
};

export default withTheme(Header);
