import React from "react";
import { Box as MuiBox } from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

const Box = styled(MuiBox)(spacing);

const TCLogo = () => {
    return (
      <Box ml={1} alignItems="center" width="100%">
        <img src="/static/img/tc-logo.png" alt="Tutoring Club" width="210px" height="35px" />
      </Box>
    );
};

export default TCLogo;