import React from "react";
import styled, { withTheme } from "styled-components";

import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Typography
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";
import { useAuth0 } from '../../react-auth0-spa';

const AppBar = styled(MuiAppBar)`
  background: ${props => props.isauthenticated !== 'true' ? props.theme.sidebar.background : props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Header = ({ onDrawerToggle, title, theme }) => {
  const { isAuthenticated } = useAuth0();
  return (
  <React.Fragment>
    <AppBar position="sticky" elevation={0} isauthenticated={isAuthenticated.toString()}>
      <Toolbar>
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Typography variant="h3" display="inline">
            {title}
          </Typography>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>);
};

export default withTheme(Header);
