import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { spacing } from "@material-ui/system";
import { isWidthUp } from "@material-ui/core/withWidth";
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth
} from "@material-ui/core";
import styled, { createGlobalStyle } from "styled-components";
import { Helmet } from 'react-helmet';

import Sidebar from "../components/binder/Sidebar";
import Header from "../components/binder/Header";
import { useAuth0 } from '../react-auth0-spa';
import { getBinder } from '../services/binder-service';
import BinderContext from "../BinderContext";
import { getStudentNotes } from '../services/binder-service';

const drawerWidth = 260;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Drawer = styled.div`
  ${props => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${props => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Binder = ({children, routes, width}) => {
  const { cmsId, lmsId } = useParams();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { isAuthenticated } = useAuth0();
  const [binder, setBinder] = useState({});
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const { hoursLog } = useContext(BinderContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const programs = useCallback(() => {
    if (!!binder && binder['enrolled_programs']) {
      return binder['enrolled_programs'];
    }
    return {};
  }, [binder]);

  useEffect(() => {
    getBinder(lmsId)
      .then(res => setBinder(res.data));
    getStudentNotes(cmsId)
      .then(res => {
        const { history,note } = res.data;
        setNote(note);
        hoursLog.push(...history);
        setTitle(res.data.studentName);
      });
  }, [cmsId, lmsId, hoursLog]);

  return (
    <Root>
      <BinderContext.Provider value={{hoursLog, note: note}}>
        <Helmet title={`${title} - Binder`} />
        <CssBaseline />
        <GlobalStyle />
        { isAuthenticated &&
        <Drawer>
          <Hidden mdUp implementation="js">
            <Sidebar
              routes={routes}
              programs={programs()}
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          </Hidden>
          <Hidden smDown implementation="css">
            <Sidebar
              routes={routes}
              programs={programs()}
              PaperProps={{ style: { width: drawerWidth } }}
            />
          </Hidden>
        </Drawer>}
        <AppContent>
          <Header onDrawerToggle={handleDrawerToggle} title={title} />
          <MainContent p={isWidthUp("lg", width) ? 10 : 5}>
            {children}
          </MainContent>
        </AppContent>
      </BinderContext.Provider>
    </Root>
  )
}

export default withWidth()(Binder);
