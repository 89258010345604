import axiosInstance from './axios-instance';


export const getUsers = (locationId) => {
  return axiosInstance.get("/users", { params: { locationId } });
};

export const getUser = (id) => {
  return axiosInstance.get(`/users/${id}`);
};

export const getUserByEmail = (email) => {
  return axiosInstance.get(`/users/${email}`);
};

export const addUser = (user) => {
  return axiosInstance.put("/users", user);
};

export const saveUser = (user) => {
  return axiosInstance.post("/users", user);
};

export const updatePermissions = (id, permissions) => {
  return axiosInstance.post(`/users/${id}/permissions`, permissions);
};

export const deletePermissions = (id, locationId) => {
  return axiosInstance.delete(`/users/${id}/permissions/${locationId}`);
};

export const deleteUser = (id) => {
  return axiosInstance.delete(`/users/${id}`);
};

export const getMe = () => {
  return axiosInstance.get("/users/me");
};

export const addAvatar = (imageFile) => {
  var formdata = new FormData();
  formdata.append("avatarUrl", imageFile);
  return axiosInstance.post("/users/upload-avatar", formdata, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  });
};

export const changePassword = (newPassword, userId) => {
  if(!!userId){
    return axiosInstance.post(`/users/${userId}/password`, {'newPassword': newPassword});
  }
  else{
    return axiosInstance.post("/users/me/password", {'newPassword': newPassword});
  }
};

export const resetPassword = (userId) => {
  return axiosInstance.post(`/users/${userId}/password/reset`);
}

export const changeUsersPassword = (userId, newPassword) => {
  return axiosInstance.post(`/users/${userId}/password`, {'newPassword': newPassword});
};

export const bulkAdd = (locationId, worksheetFile) => {
  var formdata = new FormData();
  formdata.append("file", worksheetFile);
  return axiosInstance.post(`/management/bulk-add/${locationId}`, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
  });
};
