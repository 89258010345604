import async from "../components/Async";
import Claims from '../components/permission/Claims';

// Dashboards components
const Home = async (() => import("../pages/dashboards/home"));
const OnlineOps = async (() => import("../pages/dashboards/online-ops"));

// Admin components
const AddUser = async(() => import("../pages/admin/manage-users/AddUser"));
const ManageUsers = async (() => import("../pages/admin/manage-users"));

// Online components
const StartSession = async (() => import("../pages/online/start-session"));
const ManageRooms = async (() => import("../pages/online/manage-rooms"));
const ManageRecordings = async (() => import("../pages/online/manage-recordings"));

// Student components
const Whiteboard = async (() => import("../pages/workspace/whiteboard"));

const dashboardsRoutes = {
    id: "Dashboard",
    path: "/dashboard",
    header: null,
    icon: null,
    containsHome: true,
    children: [{
            path: "/",
            name: "Home",
            component: Home
        },
        {
            path: "/dashboard/online-operations",
            name: "Online Operations",
            component: OnlineOps,
            claim: Claims.dashboardOnlineRead
        }
    ]
};

const adminRoutes = {
    id: "Admin",
    path: "/admin",
    header: null,
    icon: null,
    claim: [Claims.adminAddUsersRead, Claims.adminAddUsersEdit, Claims.adminManageUsersRead, Claims.adminManageUsersEdit],
    children: [{
        path: "/admin/manage-users/add",
        name: "Add User",
        component: AddUser,
        claim: Claims.adminAddUsersEdit
    },
    {
        path: "/admin/manage-users",
        name: "Manage Users",
        component: ManageUsers,
        claim: [Claims.adminManageUsersRead, Claims.adminManageUsersEdit]
    }]
};

const onlineRoutes = {
    id: "Online",
    path: "/online",
    header: null,
    icon: null,
    children: [{
        path: "/online/start-session",
        name: "Start Session",
        component: StartSession
    },{
        path: "/online/manage-rooms",
        name: "Manage Rooms",
        component: ManageRooms,
        claim: [Claims.onlineManageRoomsRead, Claims.onlineManageRoomsEdit]
    },{
        path: "/online/manage-recordings",
        name: "Manage Recordings",
        component: ManageRecordings,
        claim: [Claims.onlineManageRecordingsRead, Claims.onlineManageRecordingsEdit]
    }]
};

const workspaceRoutes = {
    id: "Workspace",
    path: "/workspace",
    header: null,
    icon: null,
    claim: [Claims.homePageRead, Claims.onlineJoinRead],
    children: [{
        path: "/workspace/whiteboard",
        name: "Whiteboard",
        component: Whiteboard
    }]
};

export default [dashboardsRoutes, adminRoutes, onlineRoutes, workspaceRoutes];