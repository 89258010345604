import React from "react";
import { Route, Switch } from "react-router-dom";
import async from "../components/Async";
import Default from "../layouts/Default";
import BinderLayout from "../layouts/Binder";
import PrivateRoute from "./PrivateRoute";
import siteRoutes from ".";
import Claims from "../components/permission/Claims";
import Reading from '../components/binder/Reading';
import Lesson from '../components/binder/Lesson';

// User components
const Profile = async(() => import("../pages/user/profile"));
const EditUser = async(() => import("../pages/admin/manage-users/EditUser"));
const JoinMeeting = async(() => import("../pages/join-meeting/index"));
const Binder = async(() => import("../pages/binder/index"));
const Whiteboard = async (() => import("../pages/workspace/whiteboard"));

const childRoutes = (Layout, routes, loading = false) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      (children).map(({ path, component: Component }, index) => (
        <PrivateRoute
          key={index}
          path={path}
          exact
          render={props => loading ? null : (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
        // Route item without children
        <PrivateRoute
          key={index}
          path={path}
          exact
          render={props => loading ? null : (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      )
  );

const createRoutes = (Layout, route) =>
  route.map(({ path, component: Component }, index) =>
    (<Route
      key={index}
      path={path}
      exact
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />)
  );

const userRoutes = {
  id: "User",
  path: "/user",
  header: null,
  icon: null,
  children: [{
    path: "/user/profile",
    name: "Profile",
    component: Profile
  },
  {
    path: "/admin/manage-users/:id",
    name: "User Details",
    component: EditUser,
    claim: Claims.adminManageUsersEdit
  }]
};

const workspaceRoutes = {
  id: "Workspace",
  path: "/Workspace",
  header: null,
  icon: null,
  children: [{
    path: "/workspace/whiteboard/:userId",
    name: "Whiteboard",
    component: Whiteboard
  }]
};

const guestRoutes = {
  path: "/room/join/:id",
  name: "Join Meeting",
  component: JoinMeeting
};

const binderRoutes = {
  id: "Binder",
  path: "/binder",
  header: null,
  icon: null,
  children: [
    {
      path: "/binder/:cmsId/:lmsId/:tutorId?",
      name: "Student Binder",
      component: Binder
    },
    {
      path: "/binder/:cmsId/:lmsId/:tutorId/reading",
      name: "Reading Binder",
      component: Reading
    },
    {
      path: "/binder/:cmsId/:lmsId/:tutorId/lesson/:slug",
      name: "Lesson Binder",
      component: Lesson
    }
  ]
};

const Routes = ({loading}) => {
  return (
    <Switch>
      {childRoutes(Default, siteRoutes, loading)}
      {childRoutes(Default, [userRoutes, workspaceRoutes], loading)}
      {createRoutes(Default, [guestRoutes])}
      {childRoutes(BinderLayout, [binderRoutes], loading)}
      <Route path="/404" component={() => <></>} />
    </Switch>
  );
};

export default Routes;
