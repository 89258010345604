import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withSnackbar } from 'notistack';
import { orange } from '@material-ui/core/colors';
import { spacing } from "@material-ui/system";
import { Button, Card, Typography, makeStyles, CardContent, Grid, styled, withTheme, IconButton as MuiIconButton, withStyles, TextField as MuiTextField, Tooltip } from '@material-ui/core';
import TCLogo from '../TCLogo';
import { Copy } from "react-feather";
import { addReadingGrade } from "../../services/binder-service";

const useStyles = makeStyles((theme) => ({
  header: {
    textTransform: "uppercase",
    textAlign: "center"
  },
  answer_score: {
    fontWeight: "bold",
    textAlign: "center"
  },
  answer: {
    fontWeight: "bold",
    flexGrow: 2,
    textAlign: "center",
    borderBottom: "solid 1px black"
  },
  listNumber: {
    width: "20px",
    fontWeight: "bold",
    textAlign: "center"
  },
  attackRow: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  outlined: {
    border: "solid 1px black",
    padding: "2px",
    margin: "2px",
    display: "block",
    width: "50%",
    textAlign: "center"
  },
  checkbox: {
    height: "20px",
    width: "20px",
    border: "solid 1px black",
    display: "inline-block"
  },
  bg: {
    background: theme.sidebar.background,
    padding: "10px",
    marginBottom: "10px"
  },
  center: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));


const TextField = styled(MuiTextField)(spacing);

const Answer = ({ number, value }) => {
  const classes = useStyles();

  return (
    <Grid item xs={6} style={{ display: "flex" }}>
      <span className={classes.listNumber}>
        {number}.
        </span>
      <Typography component="span" className={classes.answer}>
        {value}
      </Typography>
    </Grid>);
};

const WordAttackRow = ({ title, text }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={4} className={classes.attackRow}>
        {title}
      </Grid>
      <Grid item xs={2} className={classes.attackRow}>
        {text}
      </Grid>
      <Grid item xs={6} className={classes.attackRow}>
        completed <span className={classes.checkbox}>&nbsp;</span>
      </Grid>
    </>);
};

const AltRow = styled(Grid)({
  background: "#CCC",
  textAlign: "center"
});

const IconButton = withStyles({
  root: {
    padding: "0px",
    height: "15px",
    width: "15px"
  }
})(MuiIconButton);

const AnswerList = ({ type, bookset, book_number, answers, lesson_link, enqueueSnackbar, inputChange, inputValue }) => {
  const classes = useStyles();

  const copyToClipboard = (url) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url);
      enqueueSnackbar('Link copied to clipboard!', { variant: "info" });
    }
  };

  const tooltipText = "Type the student’s score as a percent (don’t type the  percent symbol). e.g. 80";
  const disableTextField = !answers || answers.length === 0;

  return (
    <Card>
      <CardContent>
        <Grid container alignItems="center">
          <Grid item xs={3}>
            <Typography variant="h5">{type}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Tooltip size="small" title={disableTextField ? '' : <span style={{ whiteSpace: 'pre-line' }}>{tooltipText}</span>}>
              <TextField value={!!inputValue ? inputValue : ''} size="small" variant="outlined" fullWidth my={2} margin="dense" disabled={disableTextField} onChange={e => inputChange(e.target.value)} />
            </Tooltip>
          </Grid>
          <Grid item xs={1} className={classes.center}>
            <IconButton color="primary" aria-label="Copy URL" component="span" onClick={() => copyToClipboard(lesson_link)} disabled={!lesson_link}>
              <Copy />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container>
          <AltRow item xs={6}>
            {bookset}
          </AltRow>
          <AltRow item xs={6}>
            {book_number}
          </AltRow>
          <Answer number="1" value={answers[1]} />
          <Answer number="6" value={answers[6]} />
          <Answer number="2" value={answers[2]} />
          <Answer number="7" value={answers[7]} />
          <Answer number="3" value={answers[3]} />
          <Answer number="8" value={answers[8]} />
          <Answer number="4" value={answers[4]} />
          <Answer number="9" value={answers[9]} />
          <Answer number="5" value={answers[5]} />
          <Answer number="10" value={answers[10]} />
        </Grid>
      </CardContent>
    </Card>
  );
};

const Reading = ({ theme, enqueueSnackbar, ...props }) => {

  const location = useLocation();
  const classes = useStyles();
  const routeParams = props.match.params;

  const [gradeValues, setGradeValues] = React.useState({});

  if (!location || !location.state) {
    return null;
  }

  const { vocab_1, vocab_2, vocab_3, word_attack, www, oral_reading, sight_words, comp_1, comp_2, comp_3, enrich_1, enrich_2, enrich_3 } = location.state;

  const submitReadingGrade = () => {
    let grades = [];
    for (var prop in gradeValues) {
      let gradeValue = gradeValues[prop];
      if (!!gradeValue) {
        let lessonParts = location.state[prop];
        let key = prop.charAt(0).toUpperCase() + prop.slice(1).replace('_', ' ');
        let gradeObj = {
          lesson: `${key} ${lessonParts.type} ${lessonParts.bookset} #${lessonParts.book_number}`,
          grade: gradeValue
        }
        grades.push(gradeObj);
      }
    }

    if (grades.length === 0) return;

    let studentId = routeParams.lmsId;
    let tutorId = routeParams.tutorId;
    let studentCmsId = routeParams.cmsId;

    addReadingGrade(tutorId, studentId,studentCmsId, grades).then(_ => {
      enqueueSnackbar('Grades Submitted', { variant: "success" });
      setGradeValues({});
    }).catch(err => {
      console.error(err);
      enqueueSnackbar('Grades failed to save', { variant: "error" });
    });
  };

  const gradeChange = (field, value) => {
    gradeValues[field] = value;
    setGradeValues({ ...gradeValues });
  };

  return (
    <>
      <Helmet title="Reading Program" />
      <Card>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item xs={9} className={classes.bg}>
              <TCLogo />
            </Grid>
            <Grid item xs={3} className={classes.bg} style={{ textAlign: 'right' }}>
              <Button style={{ backgroundColor: orange[800], color: 'white' }} onClick={submitReadingGrade}>
                Submit
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">Word Attack Skills</Typography>
                </Grid>
                <WordAttackRow title="Word Attack" text={word_attack.lesson} />
                <WordAttackRow title="W.W.W." text={www.lesson} />
                <WordAttackRow title="Oral Reading" text={oral_reading.lesson} />
                <Grid item xs={12}>
                  <Typography variant="body1" component="span" className={classes.outlined}>
                    W/M = 2 consecutive @ 100%
                  </Typography>
                  <Typography variant="body1" component="span" className={classes.outlined}>
                    W/M = 4 at or above 80%
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Vocabulary Skills</Typography>
                </Grid>
                <WordAttackRow title="Sight Words" text={sight_words.lesson} />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" spacing={2}>Tutor Directions</Typography>
              <ol>
                <li>Do all Word Attack, Vocabulary and Comprehension Skills before Enrichment Skills.</li>
                <li>Do WM or WM skill first, if any, and then do orange skills next.</li>
                <li>Discuss with student each and every mistake.</li>
                <li>Be positive and reward student after all lessons.</li>
                <li>Record score % after each lesson.</li>
                <li>Check Work Attack Skills, Oral Reading, WWW and Sight Word boxes after daily assignment.</li>
                <li>White "M" (Mastered) in Word Attack and Sight Words boxes when student completes skills page.</li>
                <li>Have student do 6 assignments each hour.</li>
                <li>Write a short explanation if student completes fewer than 5 lessons per hour.</li>
              </ol>
            </Grid>
            <Grid item xs={12}>

            </Grid>
          </Grid>
          <Typography variant="h1" className={classes.header}>Reading Student Program</Typography>
          <Grid container>
            <Grid item xs={4}>
              <AnswerList {...vocab_1} enqueueSnackbar={enqueueSnackbar} inputChange={(val) => { gradeChange('vocab_1', val) }} inputValue={gradeValues.vocab_1} />
            </Grid>
            <Grid item xs={4}>
              <AnswerList {...vocab_2} enqueueSnackbar={enqueueSnackbar} inputChange={(val) => { gradeChange('vocab_2', val) }} inputValue={gradeValues.vocab_2} />
            </Grid>
            <Grid item xs={4}>
              <AnswerList {...vocab_3} enqueueSnackbar={enqueueSnackbar} inputChange={(val) => { gradeChange('vocab_3', val) }} inputValue={gradeValues.vocab_3} />
            </Grid>
          </Grid>
          <Typography variant="h1" className={classes.header}>Comprehension Skills</Typography>
          <Grid container>
            <Grid item xs={4}>
              <AnswerList {...comp_1} enqueueSnackbar={enqueueSnackbar} inputChange={(val) => { gradeChange('comp_1', val) }} inputValue={gradeValues.comp_1} />
            </Grid>
            <Grid item xs={4}>
              <AnswerList {...comp_2} enqueueSnackbar={enqueueSnackbar} inputChange={(val) => { gradeChange('comp_2', val) }} inputValue={gradeValues.comp_2} />
            </Grid>
            <Grid item xs={4}>
              <AnswerList {...comp_3} enqueueSnackbar={enqueueSnackbar} inputChange={(val) => { gradeChange('comp_3', val) }} inputValue={gradeValues.comp_3} />
            </Grid>
          </Grid>
          <Typography variant="h1" className={classes.header}>Enrichment Skills</Typography>
          <Grid container>
            <Grid item xs={4}>
              <AnswerList {...enrich_1} enqueueSnackbar={enqueueSnackbar} inputChange={(val) => { gradeChange('enrich_1', val) }} inputValue={gradeValues.enrich_1} />
            </Grid>
            <Grid item xs={4}>
              <AnswerList {...enrich_2} enqueueSnackbar={enqueueSnackbar} inputChange={(val) => { gradeChange('enrich_2', val) }} inputValue={gradeValues.enrich_2} />
            </Grid>
            <Grid item xs={4}>
              <AnswerList {...enrich_3} enqueueSnackbar={enqueueSnackbar} inputChange={(val) => { gradeChange('enrich_3', val) }} inputValue={gradeValues.enrich_3} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default withSnackbar(withTheme(Reading));
