import React, { useEffect, useState } from "react";
import { NavLink as RouterNavLink, useHistory, useParams, withRouter } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Chip,
  Collapse,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import styled from "styled-components";
import { rgba, darken } from "polished";

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${props => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${props => props.theme.sidebar.background};
`;

const Items = styled.div`
  // padding-top: ${props => props.theme.spacing(2.5)}px;
  // padding-bottom: ${props => props.theme.spacing(2.5)}px;
`;

const Category = styled(ListItem)`
  padding-top: ${props => props.theme.spacing(3)}px;
  padding-bottom: ${props => props.theme.spacing(3)}px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(5)}px;
  font-weight: ${props => props.theme.typography.fontWeightRegular};

  svg {
    color: ${props => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${props => props.activeClassName} {
    background-color: ${props => darken(0.05, props.theme.sidebar.background)};

    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;

const CategorySub = styled(Category)`
  padding-left: ${props => props.theme.spacing(8)}px;
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${props => props.theme.sidebar.color};
    font-size: ${props => props.theme.typography.body1.fontSize}px;
    font-weight: ${props => props.theme.sidebar.category.fontWeight};
    padding: 0 ${props => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${props => props.theme.spacing(15)}px;
  padding-top: ${props => props.theme.spacing(2)}px;
  padding-bottom: ${props => props.theme.spacing(2)}px;

  span {
    color: ${props => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${props => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${props => props.activeClassName} {
    background-color: ${props => darken(0.06, props.theme.sidebar.background)};

    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${props => props.theme.sidebar.color};
  span {
    font-size: ${props => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${props => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${props => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${props => props.theme.sidebar.badge.color};
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const humanize = (prog) => {
  const words = prog.split("_");
  for(let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
  }
  return words.join(" ");
}

const SidebarLink = ({ name, to, badge, programType, gradeLesson, state, ...props }) => {
  const history = useHistory();
  const doNavigate = (path) => {
    history.push({pathname: path, state: {name, programType, gradeLesson, ...state}});
  };

  if (!state || !state.lesson_link) {
    return null;
  }

  return (
    <Link
      button={true}
      dense
      exact
      activeClassName="active"
      className={props.className}
      component={NavLink}
      to={to}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        doNavigate(to);
      }}
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
};

const CategoryLink = styled(SidebarLink)`
  padding-left: ${props => props.theme.spacing(10)}px;
  padding-top: ${props => props.theme.spacing(3)}px;
  padding-bottom: ${props => props.theme.spacing(3)}px;
  span {
    color: ${props => props.theme.sidebar.color};
  }
`;

const LessonDisplay = ({state, to, programType, gradeLesson}) => {
  const displayName = (l) => {
    return !!l.lesson ? `Lesson ${l.lesson}` : "";
  }
  return (
    <SidebarLink name={displayName(state)} to={to} state={state} programType={programType} gradeLesson={gradeLesson}>
    </SidebarLink>
  );
};

const ReadingProgram = ({state, cmsId, lmsId, tutorId}) => {
  state.lesson_link = true; // small hack to allow reading to show lesson link
  let gradeLesson = ""; // TODO: Pass in this value
  return (
    <SidebarLink name="Lesson" to={`/binder/${cmsId}/${lmsId}/${tutorId}/reading`} state={state} gradeLesson={gradeLesson}>
    </SidebarLink>
  );
};

const ProgramItem = ({name, index, prog, programType, openRoutes, toggle}) => {
  const { cmsId, lmsId, tutorId } = useParams();
  return (
    <>
      <SidebarCategory
        name={name}
        isOpen={!openRoutes[index]}
        isCollapsable={true}
        button={true}
        onClick={() => toggle(index)} />
      <Collapse
        in={openRoutes[index]}
        timeout="auto"
        unmountOnExit
      >
      {prog &&
        Object.keys(prog)
          .map((subject, ix) => {
            return (
              <React.Fragment key={`subject_${ix}`}>
                <SidebarCategorySub
                  button={true}
                  name={humanize(subject)}
                  item={prog[subject]} />
                {Object.keys(prog[subject])
                  .map((child, j) => {
                    const node = prog[subject][child];
                    const slug = `${subject}_${child}`;
                    const category = programType === "Writing" ? humanize(subject) : prog[subject].category;

                    return (
                      <React.Fragment key={`${child}_${ix}`}>
                        {(node instanceof Object) &&
                        <>
                          {(child === "pre_test" || child === "pretest") &&
                            <SidebarLink
                              name="Pre-Test"
                              to={`/binder/${cmsId}/${lmsId}/${tutorId}/lesson/${slug}_pretest`}
                              programType={programType}
                              gradeLesson={`${category} Level ${prog[subject].level} Pre-Test`}
                              state={node}
                            />}
                          {(child === "lessons") &&
                              node.filter(l => l.lesson != null).map(
                                lesson =>
                                  <LessonDisplay
                                    key={`${child}_${ix}_lesson_${lesson.lesson}`}
                                    to={`/binder/${cmsId}/${lmsId}/${tutorId}/lesson/${slug}_lesson${lesson.lesson}`}
                                    programType={programType}
                                    state={lesson}
                                    gradeLesson={`${category} Level ${prog[subject].level} Lesson ${lesson.lesson}`}
                                  />
                                )}
                          {(child === "post_test" || child === "posttest") &&
                            <SidebarLink
                              name="Post-Test"
                              to={`/binder/${cmsId}/${lmsId}/${tutorId}/lesson/${slug}_posttest`}
                              programType={programType}
                              state={node}
                              gradeLesson={`${category} Level ${prog[subject].level} Post-Test`}
                            />}
                        </>}
                      </React.Fragment>
                    );
                  })}
              </React.Fragment>
          );})}
        </Collapse>
    </>);
};

const ProgramList = ({ programs }) => {
  const { cmsId, lmsId, tutorId } = useParams();
  const [openRoutes, setOpenRoutes] = useState({});

  useEffect(() => {
    if (programs != null) {

      const initOpenRoutes = () => {
        let _routes = {};
        if (programs instanceof Object)
        {
          Object.keys(programs)
            .forEach((prog) => {
              _routes = Object.assign({}, _routes, {[prog]: false})
            });
        }

        return _routes;
      };

      setOpenRoutes(initOpenRoutes());
    }
  }, [programs]);

  if (!programs) {
    return null;
  }

  const toggle = index => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      item => openRoutes[index] || setOpenRoutes(openRoutes => Object.assign({}, openRoutes, {[item]: false}))
    )

    // Toggle selected element
    setOpenRoutes(openRoutes => Object.assign({}, openRoutes, {[index]: !openRoutes[index]}));
  };

  return (
    <>
      {programs.reading &&
        <>
          <SidebarCategory
            name="Reading"
            isOpen={!openRoutes["reading"]}
            isCollapsable={true}
            button={true}
            onClick={() => toggle("reading")} />
          <Collapse
            in={openRoutes["reading"]}
            timeout="auto"
            unmountOnExit
          >
            <ReadingProgram state={programs.reading} cmsId={cmsId} lmsId={lmsId} tutorId={tutorId} />
          </Collapse>
        </>}
      {programs.beginning_reading && (() => {
        const prog = programs.beginning_reading;
        const lessons = Object.keys(prog);
        return (<>
          {lessons && lessons.length &&
            <>
              <SidebarCategory
                name="Beginning Reading"
                isOpen={!openRoutes["beginning_reading"]}
                isCollapsable={lessons && lessons.length}
                button={lessons && lessons.length}
                onClick={() => toggle("beginning_reading")} />
              <Collapse
                in={openRoutes["beginning_reading"]}
                timeout="auto"
                unmountOnExit
              >
                {lessons.filter(l => prog[l].lesson != null)
                  .map(
                    (l) => {
                      const les = prog[l];
                      return (
                        <LessonDisplay
                          key={`beginning_reading_lesson_${les.lesson}`}
                          to={`/binder/${cmsId}/${lmsId}/${tutorId}/lesson/beginning_reading_lesson${les.lesson}`}
                          programType="Beginning Reading"
                          gradeLesson={`Lesson #${les.lesson}`}
                          state={les}
                        />
                      );
                    }
                  )}
              </Collapse>
            </>}
          </>);
        })()}
      {programs.math &&
        <ProgramItem index="math" name="Math" prog={programs.math} programType="Standard Math" {...{openRoutes, toggle}} />}
      {programs.cc_math &&
        <ProgramItem index="cc_math" name="CC Math" prog={programs.cc_math} programType="CC Math" {...{openRoutes, toggle}} />}
      {programs.algebra &&
        <>
          <SidebarCategory
            name="Algebra"
            isOpen={!openRoutes["algebra"]}
            isCollapsable={programs.algebra.lessons && programs.algebra.lessons.length}
            button={programs.algebra.lessons && programs.algebra.lessons.length}
            onClick={() => toggle("algebra")} />
          <Collapse
            in={openRoutes["algebra"]}
            timeout="auto"
            unmountOnExit
          >
          {Object.keys(programs.algebra)
            .map((child, j) => {
              const node = programs.algebra[child];
              const slug = `algebra_${child}`;
              
              return (
                <React.Fragment key={`${child}_${j}`}>
                  {(node instanceof Object) &&
                  <>
                    {(child === "pre_test" || child === "pretest") &&
                      <SidebarLink
                        programType="Algebra"
                        name="Pre-Test"
                        to={`/binder/${cmsId}/${lmsId}/${tutorId}/lesson/${slug}_pretest`}
                        state={node}
                        gradeLesson={`Algebra Level ${programs.algebra.level} Pre-Test`}
                      />}
                    {(child === "lessons") &&
                        node.filter(l => l.lesson != null).map(
                          lesson =>
                            <LessonDisplay
                              programType="Algebra"
                              key={`${child}_${j}_lesson_${lesson.lesson}`}
                              to={`/binder/${cmsId}/${lmsId}/${tutorId}/lesson/${slug}_lesson${lesson.lesson}`}
                              state={lesson}
                              gradeLesson={`Algebra Level ${programs.algebra.level} Lesson #${lesson.lesson} `}
                            />
                          )}
                    {(child === "post_test" || child === "posttest") &&
                      <SidebarLink
                        programType="Algebra"
                        name="Post-Test"
                        to={`/binder/${cmsId}/${lmsId}/${tutorId}/lesson/${slug}_posttest`}
                        state={node}
                        gradeLesson={`Algebra Level ${programs.algebra.level} Post-Test`}
                      />}
                  </>}
                </React.Fragment>
              );
            })}
          </Collapse>
        </>}
      {programs.writing &&
        <ProgramItem index="writing" name="Writing" prog={programs.writing} programType="Writing" {...{openRoutes, toggle}} />}
    </>
  );
};

const SidebarCategory = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) => {
  return (
    <Category
      button={true}
      {...rest}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
};

const SidebarCategorySub = ({
  name,
  item,
  ...rest
}) => {
  return (
    <CategorySub {...rest}>
      <CategoryText>{name}</CategoryText>
    </CategorySub>
  );
};

const Sidebar = ({ classes, programs, staticContext, routes, ...rest }) => {
  const { lmsId, cmsId, tutorId } = useParams();
  return (
    <Drawer variant="permanent" {...rest}>
      <Scrollbar>
        <List disablePadding>
          <Items>
            <CategoryLink name="Hours Log" to={`/binder/${cmsId}/${lmsId}/${tutorId}`} state={{lesson_link:'log'}}></CategoryLink>
            <ProgramList programs={programs} routes={routes} />
          </Items>
        </List>
      </Scrollbar>
    </Drawer>
  );
};

export default withRouter(Sidebar);
