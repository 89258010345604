
import { createContext } from "react";

const BinderAppContext = {
  hoursLog: [],
  note: ''
};

const BinderContext = createContext(BinderAppContext);

export default BinderContext;