import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import { SnackbarProvider } from 'notistack';

import maTheme from "./theme";
import Routes from "./routes/Routes";
import { useAuth0 } from "./react-auth0-spa";
import AppContext from "./AppContext";
import { getLocations, getRegions, getCountries } from './services/metadata-service';
import { getMe } from './services/user-service';
import { useLocation } from './hooks/location.hook';

function App() {
  const CURRENT_SAVED_LOCATION = "tc.currentlocation";
  const [loading, setLoading] = useState(true);
  const {isAuthenticated} = useAuth0();
  const [locations, setLocations] = useState([]);
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [me, setMe] = useState({});
  const { defaultLocations, setDefaultLocation, currentLocation, setCurrentLocation } = useLocation();

  const updateMe = useCallback(async () =>{
    return getMe()
      .then(res => {
        if (!!res && res.data) {
          setMe(res.data);
          setDefaultLocation(res.data.locations);
          const saveLoc = localStorage.getItem(CURRENT_SAVED_LOCATION)
          let currLoc = null;
          if (!!saveLoc) {
            currLoc = JSON.parse(saveLoc)
          }
          if(res.data.isGod !== true && currLoc === null){
            setCurrentLocation(res.data.locations[0]);
          }
        }
      });
  }, [setMe, setDefaultLocation]);

  useEffect(() => {
    if (isAuthenticated) {
      // get locations, regions, countries
      const getData = async () => {
        const pLoc = getLocations()
          .then(res => setLocations(res.data));
        const pRegion = getRegions()
          .then(res => setRegions(res.data));
        const pCountry = getCountries()
          .then(res => setCountries(res.data));
        const pMe = updateMe();
        Promise.all([pLoc, pRegion, pCountry, pMe])
          .then((values) => {
            setLoading(false);
          });
      };
      getData();
    }
  }, [isAuthenticated]);

  return (
    <>
      <Helmet
        titleTemplate="%s | Tutoring Club"
        defaultTitle="Tutoring Club Portal"
      />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[0]}>
            <ThemeProvider theme={maTheme[0]}>
              <AppContext.Provider value={{locations, regions, countries, me, updateMe, currentLocation, defaultLocations, setCurrentLocation, setDefaultLocation, loading}}>
                <SnackbarProvider
                  maxSnack={5}>
                 <Routes loading={loading} />
                </SnackbarProvider>
              </AppContext.Provider>
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </>
  );
}

export default App;
