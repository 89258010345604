const Claims = Object.freeze({
    homePageRead: "homepage:read",
    homePageEdit: "homepage:edit",
    dashboardOperationsRead: "dashboard-operations:read",
    dashboardFinancialRead: "dashboard-financial:read",
    dashboardOnlineRead: "dashboard-online:read",
    dashboardSalesRead: "dashboard-sales:read",
    adminManageUsersRead: "admin-manageusers:read",
    adminManageUsersEdit: "admin-manageusers:edit",
    adminManageUserPasswordEdit: "admin-manageusers:passwordedit",
    adminAddUsersRead: "admin-addusers:read",
    adminAddUsersEdit: "admin-addusers:edit",
    onlineStartRead: "online-start:read",
    onlineJoinRead: "online-join:read",
    onlineManageRoomsRead: "online-managerooms:read",
    onlineManageRoomsEdit: "online-managerooms:edit",
    onlineManageRecordingsRead: "online-managerecordings:read",
    onlineManageRecordingsEdit: "online-managerecordings:edit"
});

export default Claims;